<template>
 <div class="mt-5">
  <Header></Header>
   <Tabla class="mt-5"/>
 </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'ListadoJuzgados',
  components: {
    Tabla: defineAsyncComponent(
      () => import('./components/Tabla.vue')
    ),
    Header: defineAsyncComponent(
      () => import('./components/Header.vue')
    )
  }
}
</script>
<style scoped>

</style>
